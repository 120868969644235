@import '_variables.scss';
@import "~bootstrap/scss/bootstrap";
@import '_fonts.scss';
@import '_typography.scss';

*, html {
  scroll-behavior: auto !important;
}

body {
  background: $background;
  margin: 0;
}

h1 {
  color: $primary;
}

.navbar {
  padding: 0;

  .nav-link {
    min-height: 60px;
    display: inline-flex;
    align-items: center;
    padding: 10px 15px !important;
    font-size: 16px;
    //min-width:220px;

    &.hidden {
      display: none;
    }

    &:hover {
      background: darken($primary, 5%);
    }

    &.active {
      background: darken($primary, 10%);
    }
  }
}

// Generic styles for all tables
table {
  table-layout: fixed;

  * {
    box-sizing: border-box;
    border: none;
  }

  tr {
    vertical-align: middle;
    background: #D9D9D9;
    opacity: 1 !important;
    display: table-row;
    align-items: center;

    &[style*="display: flex"] {
      td {
        display: inline-flex;
        align-items: center;
      }
    }
  }

  &.table-tracks {
    .song {
      width: 45%;
    }

    .artist {
      width: 45%;
    }

    .platform {
      width: 110px;

      &[colspan="2"] {
        width: 220px;
      }
    }

    .actions {
      width: 104px;
      text-align: center;
    }
  }

  &.table-albums {
    td, th {
      &:nth-child(2) {
        width: 91px;

        img {
          width: 100%;
        }
      }

      &:nth-child(3) {
        width: 35%;
      }

      &:nth-child(5),
      &:nth-child(6) {
        width: 85px;

        &[colspan="2"] {
          width: 170px;
        }
      }

      &:last-child {
        width: 144px;
      }
    }
  }

  &.table-playlist {
    td, th {
      &:nth-child(2) {
        width: 91px;

        img {
          width: 100%;
        }
      }

      &:nth-child(3) {
        width: 25%;
      }

      &:last-child {
        width: 144px;
      }
    }
  }


  // Move styles
  .move {
    width: 30px;

    i {
      opacity: 0.3;
      transition: all .2s ease-in-out;
    }

    &:hover, &:active {
      i {
        opacity: 1;
        color: $primary;
      }
    }

    &:active {
      cursor: grabbing;
    }
  }

  //&.table-playlist{
  //  table-layout: fixed;
  //  tr{
  //    width:100%;
  //  }
  //  td, th{
  //    &:nth-child(2){
  //      width:110px;
  //      img{
  //        width:110px;
  //        max-width:100%;
  //      }
  //    }
  //
  //    &:nth-child(3){
  //      width:20%;
  //    }
  //
  //    &:nth-child(4){
  //      width:70%;
  //    }
  //
  //    &:last-child{
  //      width:140px;
  //    }
  //  }
  //}
}

.album-art-bg {
  background-size: cover;
}

// Top of Album / PLaylist view


.e-top {
  display: flex;
  margin-top: 20px;
  width: 100%;
  align-items: center;
  position: relative;

  .image {
    margin-right: 20px;
    width: 130px;
    position: relative;

    img {
      border-radius: 30px;
      width: 100%;
    }

  }

  .text {
    width: calc(100% - 150px);

    h1 {
      margin-bottom: 10px;
    }

    p {
      margin: 0;
      font-size: 20px;
      line-height: 28px;
    }
  }
}

.e-back {
  text-decoration: none;
  font-size: 22px;
  line-height: 32px;
  font-weight: 700;
  border: none;
  background: none;
  color: $primary;
  transition: all .3s ease-in-out;

  &:hover {
    cursor: pointer;
    opacity: 0.5;
  }

  @media(min-width: 600px) {
    margin: 0 0 20px;
  }
}


.selectTracks {
  max-height: 80vh;
  overflow: auto;
  font-size: 16px;
  line-height: 18px;

  .trackTop {
    border-bottom: 1px solid #DFDFDF;
    display: flex;
    font-weight: 700;

    & > div {
      padding: 15px 10px;

      &:first-child {
        width: 40%;
      }

      &:nth-child(2) {
        width: 40%;
      }

      &:last-child {
        width: 20%;
      }
    }
  }

  .trackSelect {
    display: flex;
    position: relative;
    transition: all .2s ease-in-out;

    & > div {
      padding: 15px 10px;
      position: relative;
      z-index: 10;

      &:first-child {
        width: 40%;
      }

      &:nth-child(2) {
        width: 40%;
      }

      &:last-child {
        width: 20%;
      }
    }

    &:hover {
      background: #EDBFCA;
      cursor: pointer;
    }

    &.active {
      background: $primary;
      color: #FFF;
    }

    &:nth-child(odd) {
      background: #eee;

      &:hover {
        background: #EDBFCA;
      }

      &.active {
        background: $primary;
        color: #FFF;
      }
    }
  }
}
